.account-wrapper {
  margin-top: 30px;
  display: grid;
  width: 100%;
  grid-template-columns: 200px auto;
  h1 {
    font-size: 26px;
  }
  h2 {
    font-size: 20px;
  }
  input {
    border: 1px hsl(0, 0%, 80%) solid;
    border-radius: 4px;
    min-height: 38px;
    padding-left: 10px;
    padding-right: 10px;
    transition: 0.2s;
    width: 100% !important;
    margin-bottom: 10px;
  }
  button {
    width: 200px;
  }

  ul {
    li {
      font-weight: 500;
      font-size: 18px;
    }
  }

  .personal-information {
    display: flex;
    flex-direction: column;
    .row {
      display: grid;
      grid-template-columns: 150px 1fr;
    }
  }
}