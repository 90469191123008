.category-wrapper {
  .category-categories-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    padding: 40px 0;
    border-bottom: 1px solid #B95BFE;
    margin-bottom: 40px;
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        margin-top: 10px;
        font-size: 18px;
      }
      a {
        border: 1px solid #B95BFE;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        width: 100%;

        &:hover {
          -webkit-box-shadow: 0 0 17px -4px rgba(0, 0, 0, 0.75);
          -moz-box-shadow: 0 0 17px -4px rgba(0, 0, 0, 0.75);
          box-shadow: 0 0 17px -4px rgba(0, 0, 0, 0.75);
        }

        img {
          object-fit: cover;
          height: 200px;
          width: 100%;
        }
      }
    }
  }

  .page-numbers {
    margin: 40px 0;
    display: flex;
    justify-content: center;
    gap: 20px;
    .previous-page-category, .next-page-category {
      left: 0;
      right: 0;
      position: relative;
      height: 40px;
      width: 40px;
      background-color: #a44fe7;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 50%;
      border-radius: 16px;
      cursor: pointer;
      transition: 0.2s;
      box-shadow: 0 0 17px -4px rgba(0, 0, 0, 0.75);
    }
    button {
      transition: .2s;
      font-weight: 600;
      &:hover {
        color: #7a7a7a;
      }
    }
    .active {
      color: #c990ee;
    }
  }

  .category-products-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
    gap: 20px;
    text-align: center;
    a {
      background-color: white;
      display: grid;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      padding: 40px;
      transition: .2s;
      border: 1px solid #B95BFE;
      width: 100%;
      height: 100%;

      .product-div {
        display: grid;
        height: 100%;
      }

      &:hover {
        -webkit-box-shadow: 0 0 17px -4px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0 0 17px -4px rgba(0, 0, 0, 0.75);
        box-shadow: 0 0 17px -4px rgba(0, 0, 0, 0.75);
      }

      img {
        height: 200px;
        object-fit: cover;
        object-position: top;
      }

      .product-title {
        margin-top: 20px;
        font-size: 32px;
      }

      .product-subcat {
        font-size: 16px;
        font-weight: 300;
        margin-top: -8px;
      }

      .product-price {
        margin-top: 10px;
        color: #B41E0A;
        font-size: 20px;
        align-self: end;
      }

      button {
        margin-top: 20px;
        width: 100%;
        align-self: end;
      }

      .product-image {
        object-fit: cover;
      }
    }
  }
}