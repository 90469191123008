.product-wrapper {
  margin-top: 70px;
  display: grid;
  grid-template-columns: 50% auto;
  gap: 100px;
  width: 100%;
}
.product-wrapper .product-images {
  display: flex;
  justify-content: center;
}
.product-wrapper .product-images img {
  height: 500px;
  width: fit-content;
}
.product-wrapper .product-images .sub-images {
  margin-top: 20px;
}
.product-wrapper .product-images .sub-images .sub-image {
  background-color: #282c34;
  width: 200px;
  height: 150px;
}
.product-wrapper .product-info h1 {
  font-size: 48px;
  font-weight: 500;
  margin-left: -2px;
}
.product-wrapper .product-info h2 {
  margin-top: -15px;
  font-size: 20px;
  font-style: italic;
}
.product-wrapper .product-info .description {
  margin-top: 20px;
  margin-bottom: 50px;
}
.product-wrapper .product-info div.price-wrapper {
  margin-bottom: 10px;
}
.product-wrapper .product-info div.price-wrapper p.price {
  color: #e75b48;
  font-size: 32px;
  font-weight: 500;
}
.product-wrapper .product-info div.price-wrapper span.item-in-stock {
  font-size: 12px;
  font-weight: 500;
  border: 1px solid #57ab5a;
  color: #57ab5a;
  background-color: rgba(186, 245, 189, 0.5);
  padding: 2px 8px;
  border-radius: 80px;
}
.product-wrapper .product-info div.price-wrapper span.item-out-stock {
  font-size: 12px;
  font-weight: 500;
  border: 1px solid #b40000;
  color: #b40000;
  background-color: rgba(162, 50, 50, 0.5);
  padding: 2px 8px;
  border-radius: 80px;
}
.product-wrapper .product-info button {
  font-weight: 600;
}
.product-wrapper .product-info button svg {
  margin-right: 10px;
}
.product-wrapper .product-info button:last-of-type {
  background-color: #250041;
}
.product-wrapper .product-info button:last-of-type:hover {
  background-color: #5a426e;
}
.product-wrapper .product-info button:last-of-type:disabled {
  background-color: #5a426e;
}
.product-wrapper .product-info .rating {
  margin-top: 10px;
}

.previous-page, .next-page {
  height: 40px;
  width: 40px;
  background-color: #a44fe7;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  border-radius: 16px;
  cursor: pointer;
  transition: 0.2s;
  -webkit-box-shadow: 0 0 17px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 0 17px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 0 0 17px -4px rgba(0, 0, 0, 0.75);
}
.previous-page:hover, .next-page:hover {
  background-color: #c990ee;
}

.previous-page {
  left: -20px;
}

.next-page {
  right: -20px;
}

.products-recommendations {
  margin-top: 100px;
}
.products-recommendations .products-wrapper {
  width: 100%;
  position: relative;
}
.products-recommendations .products-wrapper .products-grid {
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 15px;
}
.products-recommendations .products-wrapper .products-grid a {
  min-width: 100%;
}
.products-recommendations .products-wrapper .products-grid a div {
  width: 100%;
  text-align: center;
  padding: 25px;
  justify-content: center;
}
.products-recommendations .products-wrapper .products-grid img {
  justify-self: center;
  object-position: top;
  width: 300px;
  height: 200px;
}

