.max-w-l {
  max-width: 1420px;
}

.border-radius {
  border-radius: 16px;
}

.modal-wrapper {
  width: 100%;
  .modal-title {
    border-bottom: solid 1px hsl(0, 0%, 80%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    font-size: 20px;
    padding: 10px;
    svg {
      cursor: pointer;
      transition: .2s;
      &:hover {
        color: #aaa;
      }
    }
  }

  .modal-content {
    width: 100%;
    margin-top: 50px;
    form > div {
      width: 100%;
      input:not(input[type="select"]) {
        border: 1px hsl(0, 0%, 80%) solid;
        border-radius: 4px;
        min-height: 38px;
        padding-left: 10px;
        padding-right: 10px;
        transition: .2s;
        width: 100%;

        &:focus {
          border-color: #a44fe7;
        }
      }
    }
  }
}

.cart-badge {
    position: absolute;
    background-color: #e75b48;
    display: block;
    min-width: 25px;
    padding: 1px 10px;
    text-align: center;
    border-radius: 80px;
    color: #ffffff;
    top: 30px;
    right: -5px;
}

nav.desktop {
  display: flex;
  grid-template-columns: 100%;
  flex-direction: column;
  position: fixed;
  background-color: white;
  left: 0;
  right: 0;
  z-index: 99;
  -webkit-box-shadow: 0 0 17px -4px rgba(0,0,0,0.75);
  -moz-box-shadow: 0 0 17px -4px rgba(0,0,0,0.75);
  box-shadow: 0 0 17px -4px rgba(0,0,0,0.75);
  .nav-wrapper {
    width: 100%;
    align-self: center;
    display: grid;
    grid-template-columns: 30% 1fr 30%;
    align-items: center;
    height: 71px;
    align-content: center;
    .identity {
      display: flex;
      //justify-self: start;
      font-weight: 600;
      font-size: 20px;
      a {
        justify-self: center;
        display: flex;
        gap: 10px;
      }
      img {
        width: 65px;
        height: 65px;
      }

      .shop-name {
        align-self: center;
      }
    }
    .navigation-links {
      justify-self: end;
      height: 100%;
      ul {
        gap: 20px;
        display: flex;
        height: 100%;

        .user-icon:hover .user-dropdown {
          display: block;
        }
        .user-dropdown:hover .user-icon {
          transition: .2s;
          &:hover {
            color: #aaa;
          }
        }
        li.user-icon {
          height: 71px;
          svg {
            width: 25px;
          }
        }
        li {
          position: relative;
          display: flex;
          align-items: center;
          min-width: 50px;
          justify-content: center;
          svg {
            width: 30px;
            height: auto;
          }
          a {
            display: flex;
          }
          .user-dropdown:hover {
            display: block;
          }
          .user-dropdown {
            display: none;
            position: absolute;
            width: 200px;
            background-color: white;
            top: 71px;
            right: -10px;
            border: 1px solid black;
            border-top: 0;
            ul {
              display: flex;
              flex-direction: column;
              gap: 0;
              p {
                padding: 10px;
                border-bottom: 1px solid black;
              }
              li {
                border-top: 1px solid black;
                a, button {
                  display: inline-block;
                  width: 100%;
                  padding: 10px;
                  text-align: left;
                  transition: .2s;
                  &:hover {
                    color: #aaa;
                  }
                }
              }
              li:first-of-type {
                border-top: 0;
              }
            }
          }
        }
        a {
          transition: .2s;
          &:hover {
            color: #aaa;
          }
        }
      }
    }
  }
}

nav.mobile {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 99;
  background-color: white;
  justify-content: center;
  -webkit-box-shadow: 0 0 17px -4px rgba(0,0,0,0.75);
  -moz-box-shadow: 0 0 17px -4px rgba(0,0,0,0.75);
  box-shadow: 0 0 17px -4px rgba(0,0,0,0.75);

  .nav-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 80% 20%;
    height: 71px;
    align-items: center;
    align-self: center;
    align-content: center;

    .identity {
      display: flex;
      //justify-self: start;
      font-weight: 600;
      font-size: 20px;
      img {
        width: 32px;
        height: 32px;
      }
      a {
        justify-self: center;
        display: flex;
        gap: 20px;
        margin-left: 20px;
      }
    }
    a {
      transition: .2s;
      position: relative;
      span.cart-badge {
        right: -15px;
        top: 35px;
      }
      &:hover {
        color: #aaa;
      }
      justify-self: end;

      svg {
        width: 30px;
        height: 80px;
      }
    }
  }

  .mobile-menu {
    background-color: white;
    display: flex;
    position: fixed;
    top: 71px;
    bottom: 0;
    left: 0;
    right: 0;
    transition: .2s;
    justify-content: center;
    ul {
      margin-top: 20px;
      width: 90%;
      display: flex;
      flex-direction: column;
      font-size: 26px;
      gap: 20px;
      div {
        position: fixed;
        bottom: 20px;
        li:not(:last-of-type) {
          margin-bottom: 20px;

          svg {
            margin-right: 30px;
          }
        }
        svg {
          margin-right: 15px;
        }
      }
    }
  }
  .menu-open {
    transform: translateX(0%) !important;
  }.menu-closed {
     transform: translateX(-100%) !important;
   }
}

.main-wrapper {
  top: 71px;
  position: relative;
  //z-index: 1;
}

.hero-image {
  width: 100%;
  height: 587px;
  position: relative;
  z-index: 1;
  background-image: linear-gradient(rgba(48, 48, 48, 0.57), rgba(48, 48, 48, 0.57)), url("/public/image 1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .hero-text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -45%);
    color: white;
    h1 {
      font-size: 48px;
      font-weight: 600;
    }
    p {
      max-width: 700px;
      margin-top: 70px;
      font-size: 20px;
    }
  }
}

.categories-navigation-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .categories-navigation {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
    gap: 20px;
    text-align: center;

    .category-div {
      max-width: 400px;
      .category-image {
        width: 100%;
        height: auto;transition: .2s;
        //border: 2px solid #B95BFE;
        //padding: 10px;
        &:hover {
          -webkit-box-shadow: 0 0 17px -4px rgba(0,0,0,0.75);
          -moz-box-shadow: 0 0 17px -4px rgba(0,0,0,0.75);
          box-shadow: 0 0 17px -4px rgba(0,0,0,0.75);
        }
      }
      p {
        margin-top: 10px;
        font-size: 24px;
      }
    }
  }
}

.breadcrumb-separator {
  color: #aaa;
}

.breadcrumb {
  color: #aaa;
}

.homepage-wrapper {
  width: 100%;
}
.separator-text {
  text-align: center;
  width: 100%;
  padding: 40px 0;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: 30px;
  span {
    height: 1px;
    background-color: #aaa;
    width: auto;
  }
  p {
    font-size: 32px;
    width: fit-content;
  }
}

.loader-icon {
  font-size: 70px;
}

.products-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .products-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
    gap: 20px;
    text-align: center;
    a {
      display: flex;
      justify-content: center;
      height: 100%;
    }

    .button-see-more {
      margin-top: 20px;
      background-color: #250041;
      height: 50px;
      display: flex;
      align-items: center;
      border-radius: 90px;
      grid-column: 2;
      &:hover {
        background-color: #5a426e;
      }
    }
  }
  .product-div {
    background-color: white;
    display: grid;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding: 40px;
    transition: .2s;
    border: 2px solid #B95BFE;
    width: 80%;
    &:hover {
      -webkit-box-shadow: 0 0 17px -4px rgba(0,0,0,0.75);
      -moz-box-shadow: 0 0 17px -4px rgba(0,0,0,0.75);
      box-shadow: 0 0 17px -4px rgba(0,0,0,0.75);
    }
    .product-title {
      margin-top: 20px;
      font-size: 32px;
    }
    .product-subcat {
      font-size: 16px;
      font-weight: 300;
      margin-top: -8px;
    }
    .product-price {
      margin-top: 10px;
      color: #B41E0A;
      font-size: 20px;
      align-self: end;
    }
    button {
      margin-top: 20px;
      width: 100%;
      align-self: end;
    }
    .product-image {
      object-fit: cover;
    }
  }
}

.button-action {
  text-align: center;
  color: #ffffff;
  background-color: #B95BFE;
  padding: 10px 0;
  transition: .2s;
  width: 75%;
  &:hover {
    background-color: #c990ee;
    -webkit-box-shadow: 0 0 17px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 0 0 17px -4px rgba(0,0,0,0.75);
    box-shadow: 0 0 17 -4px rgba(0,0,0,0.75);
  }

  &:disabled {
    background-color: #786785;
    opacity: .6;
  }
}

.button-delete {
  background-color: #fe5b5b;
  &:hover {
    background-color: #ee9090;
  }

  &:disabled {
    background-color: #856767;
  }
}

footer {
  -webkit-box-shadow: 0 0 17px -4px rgba(0,0,0,0.75);
  -moz-box-shadow: 0 0 17px -4px rgba(0,0,0,0.75);
  box-shadow: 0 0 17px -4px rgba(0,0,0,0.75);
  background-color: #fff;
  height: 541px;
  width: 100%;
  margin-top: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  flex-direction: column;
  position: relative;

  img {
    width: 133px;
    height: 133px;

    margin-top: -60px;
  }
  >a, >p {
    margin-top: 20px;
  }

  .copyright {
    position: absolute;
    bottom: 0;
    padding: 30px;
    left: 0;
    right: 0;
    text-align: center;
    border-top: 1px solid #D1D1D1;
  }
}

.login-wrapper {
  justify-content: center;
  min-height: 65vh;
  .login-modal {
    -webkit-box-shadow: 0 0 17px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 0 0 17px -4px rgba(0,0,0,0.75);
    box-shadow: 0 0 17px -4px rgba(0,0,0,0.75);
    margin-top: -200px;
    background-color: #fff;
    width: 600px;
    border: 2px solid #B95BFE;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    padding: 40px 0;
    position: absolute;
    top: 300px;
    .input-login-control {
      width: 75%;
      input {
        width: 100%;
      }
    }
    h1 {
      font-size: 26px;
      margin-bottom: 40px;
      font-weight: 600;
    }
    a {
      width: 75%;
    }
    .separator-text {
      width: 75%;
      padding: 0;
      p {
        font-size: 12px;
      }
    }
    .button-register {
      margin-top: 0;
    }
    a {
      color: #a44fe7;
      font-size: 16px;
      &:hover {
        color: #B95BFE;
      }
    }
  }
}

.form-control {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

input.border-red {
  border-color: #e75b48 !important;
}

input.email-input {
  border: 1px solid black;
  height: 36px;
  background: url("/public/envelope.svg") no-repeat scroll 10px 7px;
  background-size: 20px 20px;
  padding-left: 40px;
  padding-right: 10px;
  transition: .2s;
  &:focus {
    border-color: #a44fe7;
  }
}

input.password-input {
  border: 1px solid black;
  height: 36px;
  background: url("/public/lock.svg") no-repeat scroll 10px 7px;
  background-size: 20px 20px;
  padding-left: 40px;
  padding-right: 10px;
  transition: .2s;
  &:focus {
    border-color: #a44fe7;
  }
}input.username-input {
  border: 1px solid black;
  height: 36px;
  background: url("/public/at-light.svg") no-repeat scroll 10px 7px;
  background-size: 20px 20px;
  padding-left: 40px;
  padding-right: 10px;
  transition: .2s;
  &:focus {
    border-color: #a44fe7;
  }
}input.firstname-input {
  border: 1px solid black;
  height: 36px;
  background: url("/public/id-card-light.svg") no-repeat scroll 10px 7px;
  background-size: 20px 20px;
  padding-left: 40px;
  padding-right: 10px;
  transition: .2s;
  &:focus {
    border-color: #a44fe7;
  }
}input.lastname-input {
  border: 1px solid black;
  height: 36px;
  background: url("/public/id-card-light.svg") no-repeat scroll 10px 7px;
  background-size: 20px 20px;
  padding-left: 40px;
  padding-right: 10px;
  transition: .2s;
  &:focus {
    border-color: #a44fe7;
  }
}

.search-bar {
  justify-self: center;
  display: flex;
  width: 100%;
  position: relative;

  .search-bar-dropdown {
    position: absolute;
    flex-direction: column;
    background-color: white;-ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;
    max-height: 240px;
    overflow-y: scroll;
    top: 20px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    left: 0;
    right: 0;
    border: 1px solid #a44fe7;
    border-top: 0;
    z-index: 2;
    transition: .2s;
    ul {
      padding: 25px 10px 10px 10px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      li a, .no-result {
        padding: 10px 15px;
        border-radius: 16px;
        background-color: #eaeaea;
        display: grid;
        width: 100%;
        grid-template-columns: 60% auto 50px;
        align-items: center;
        span:last-of-type {
          font-style: italic;
          font-size: 11px;
          justify-self: start;
          margin-bottom: -2px;
        }
        svg {
          justify-self: end;
        }
      }
    }
  }
  input {
    border: 1px solid black;
    height: 36px;
    width: 100%;
    background: url("/public/search-alt.svg") no-repeat scroll 10px 7px;
    background-size: 20px 20px;
    padding-left: 40px;
    padding-right: 50px;
    transition: .2s;
    position: relative;
    z-index: 5;
    background-color: #fff;

    &:focus + button{
      border-color: #a44fe7;
    }
    &:focus {
      border-color: #a44fe7;
    }
  }

  button {
    margin-left: -45px;
    border-left: 1px solid black;
    padding-left: 10px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    padding-right: 12px;
    transition: .2s;
    height: 34px;
    margin-top: 1px;
    position: relative;
    z-index: 5;
    &:hover {
      background-color: #c990ee;
    }
  }
}

@media only screen and (max-width: 1440px) {
  .max-w-l {
    max-width: 1220px;
  }
}

@media only screen and (max-width: 1240px) {
  .previous-page, .next-page {
    top: -60px !important;
  }
  .previous-page {
    left: 200px !important;
  }

  .next-page {
    right: 200px !important;
  }

  .category-wrapper {
    .category-categories-wrapper, .category-products-wrapper {
      grid-template-columns: 1fr 1fr 1fr !important;
    }
  }

  .max-w-l {
    max-width: 1020px;
  }

  .categories-navigation {
    grid-template-columns: 1fr 1fr !important;
  }

  .products-grid {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
  }
}

@media only screen and (max-width: 1040px) {
  .order-status {
    width: 100% !important;
  }
  .max-w-l {
    max-width: 920px;
  }
}

@media only screen and (max-width: 990px) {
  .max-w-l {
    max-width: 90%;
  }

  .category-wrapper {
    .category-categories-wrapper, .category-products-wrapper {
      grid-template-columns: 50% 50% !important;
    }
  }

  nav.desktop {
    display: none;
  }

  nav.mobile {
    display: flex;
  }

  .product-wrapper {
    margin-top: 30px!important;
    grid-template-columns: 100% !important;
    grid-template-rows: auto auto;
    gap: 30px!important;
  }
}

@media only screen and (max-width: 768px) {

  .order-product {
    width: 100% !important;
  }

  .previous-page {
    left: 100px !important;
  }

  .next-page {
    right: 100px !important;
  }

  .categories-navigation {
    grid-template-columns: 1fr !important;
  }

  .products-grid {
    display: grid;
    grid-template-columns: 1fr !important;
    .button-see-more {
      grid-column: 1 !important;
    }
  }

  .products-wrapper {
    .products-grid {
    }
    .product-div {
      width: 75%;
    }
  }

  .categories-navigation-wrapper {
    width: 100%;
    .categories-navigation {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      justify-items: center;
      align-items: center;
      gap: 20px;
      text-align: center;

      .category-div {
        max-width: 75%;
        .category-image {
          width: 100%;
          height: auto;
        }
        p {
          margin-top: 10px;
          font-size: 24px;
        }
      }
    }
  }
}

@media only screen and (max-width: 755px) {
  .category-wrapper {
    .category-products-wrapper {
      grid-template-columns: 1fr !important;
      img {
        height: 400px !important;
      }
    }
  }

  .hero-image {
    .hero-text {
      width: 80%;
      h1 {
        max-width: 100%;
      }
      p {
        max-width: 100%;
        margin-top: 70px;
        font-size: 20px;
      }
    }
  }
}

@media only screen and (max-height: 850px) {
  .login-wrapper {
    min-height: 70vh;
  }
}

@media only screen and (max-height: 760px) {
  .login-wrapper {
    min-height: 80vh;
  }
}