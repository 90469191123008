.product-wrapper {
    margin-top: 70px;
    display: grid;
    grid-template-columns: 50% auto;
    gap: 100px;
    width: 100%;

    .product-images {
        display: flex;
        justify-content: center;
        img {
            height: 500px;
            width: fit-content;
        }
        .sub-images {
            margin-top: 20px;
            .sub-image {
                background-color: #282c34;
                width: 200px;
                height: 150px;
            }
        }
    }

    .product-info {
        h1 {
            font-size: 48px;
            font-weight: 500;
            margin-left: -2px;
        }

        h2 {
            margin-top: -15px;
            font-size: 20px;
            font-style: italic;
        }

        .description {
            margin-top: 20px;
            margin-bottom: 50px;
        }

        div.price-wrapper {
            margin-bottom: 10px;
            p.price {
                color: #e75b48;
                font-size: 32px;
                font-weight: 500;
            }
            span.item-in-stock {
                font-size: 12px;
                font-weight: 500;
                border: 1px solid #57ab5a;
                color: #57ab5a;
                background-color: rgba(186, 245, 189, 0.5);
                padding: 2px 8px;
                border-radius: 80px;
            }
            span.item-out-stock {
                font-size: 12px;
                font-weight: 500;
                border: 1px solid #b40000;
                color: #b40000;
                background-color: rgba(162, 50, 50, 0.5);
                padding: 2px 8px;
                border-radius: 80px;
            }
        }

        button {
            font-weight: 600;
            svg {
                margin-right: 10px;
            }
            &:last-of-type {
                background-color: #250041;
                &:hover {
                    background-color: #5a426e;
                }
                &:disabled  {
                    background-color: #5a426e;
                }
            }
        }

        .rating {
            margin-top: 10px;
        }
    }
}

.previous-page, .next-page {
    height: 40px;
    width: 40px;
    background-color: #a44fe7;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    border-radius: 16px;
    cursor: pointer;
    transition: .2s;
    -webkit-box-shadow: 0 0 17px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 0 0 17px -4px rgba(0,0,0,0.75);
    box-shadow: 0 0 17px -4px rgba(0,0,0,0.75);
    &:hover {
        background-color: #c990ee;
    }
}

.previous-page {
    left: -20px;
}

.next-page{
    right: -20px;
}

.products-recommendations {
    margin-top: 100px;
    .products-wrapper {
        width: 100%;
        position: relative;
        .products-grid {
            width: 100%;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 15px;
            a {
                div {
                    width: 100%;
                    text-align: center;
                    padding: 25px;
                    justify-content: center;
                }
                min-width: 100%;
            }
            img {
                justify-self: center;
                object-position: top;
                width: 300px;
                height: 200px;
            }
        }
    }
}