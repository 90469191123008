.orders-wrapper {
  padding-top: 70px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .order-status {
    h1 {
      font-size: 32px;
    }
    display: flex;
    flex-direction: column;
    width: 75%;
    padding: 20px;
    background-color: #bceebd;
    border: 1px solid #57ab5a;
    gap: 10px;
    align-items: center;
  }

  .order-product {
    margin-top: 40px;
    width: 500px;
    gap: 0 30px;
    display: grid;
    align-items: center;
    justify-items: start;
    grid-template-columns: 25% auto;
    div {
      display: grid;
      grid-template-columns: 100px auto;
    }
    div:first-of-type {
      align-self: end;
    }
    div:last-of-type {
      align-self: start;
    }
    img {
      grid-row: 1 / span 3;
    }
  }
}