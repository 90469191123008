.main-wrapper-admin {
  margin-left: 200px;
}

.admin-dashboard-wrapper {
  margin-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: center;
  .products-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    .products-table {
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      table {
        width: 75%;
      }
    }
  }

  .categories-wrapper {
    margin-top: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    align-content: center;
    .categories-table {
      width: 75%;
    }
  }

  td, th {
    padding: 10px;
    button {
      width: 100%;
    }
  }
}