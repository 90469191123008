.cart-wrapper {
  display: grid;
  grid-template-columns: 60% auto;
  width: 100%;
  gap: 50px;
  margin-top: 70px;

  .articles{
    display: flex;
    width: 100%;
    flex-direction: column;
    .remove-all-btn {
      align-self: end;
      margin-top: 5px;
    }
    h1 {
      svg {
        margin-right: 15px;
        height: 32px;
      }
      font-size: 36px;
    }
    .article {
      border: 1px solid #E4E4E4;
      padding: 10px;
      display: grid;
      grid-template-columns: 15% 80px auto 20%;
      grid-template-rows: 20% 20% 1fr;
      gap: 0 15px;
      margin-top: 10px;
      background-color: white;
      .category {
        grid-row: 1;
        grid-column: 2/5;
        font-size: 20px;
        font-weight: 300;
      }
      .article-name {
        grid-row: 2;
        grid-column: 2/5;
        font-size: 24px;
        font-weight: 500;
      }
      .amount {
        grid-row: 3;
        grid-column: 2;
        align-self: center;
        width: 80px;
        height: 40px;
        padding: 0 5px;
        padding-left: 20px;
        border: 1px solid #E4E4E4;
        -moz-appearance:none; /* Firefox */
        -webkit-appearance:none; /* Safari and Chrome */
        appearance:none;
        background: url("/public/chevron-down-light.svg");
        background-size: 20px;
        background-repeat: no-repeat;
        background-position-x: 90%;
        background-position-y: 8px;
      }
      .article-total {
        grid-row: 3;
        grid-column: 4;
        font-size: 32px;
        font-weight: 500;
        color: #e75b48;
        align-self: center;
        justify-self: end;
        margin-right: 30px;
      }
      .delete {
        grid-row: 3;
        grid-column: 3;
        align-self: center;
        font-size: 20px;
        cursor: pointer;
        transition: .2s;
        &:hover {
          color: #aaa;
        }
      }
      img {
        grid-row: 1/4;
        align-self: center;
      }
    }
  }
  div.right-col {
    margin-top: 64px;
  }

  .address, .user {
    border: 1px solid #E4E4E4;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    background-color: white;
    margin-bottom: 20px;
    grid-column: 2;

    input {
      border: 1px hsl(0, 0%, 80%) solid;
      border-radius: 4px;
      min-height: 38px;
      padding-left: 10px;
      padding-right: 10px;
      transition: 0.2s;
      width: 100% !important;
      margin-bottom: 10px;
    }

    h1 {
      font-size: 36px;
      margin-bottom: 10px;
    }

    button{
      align-self: center;
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }

  .user {
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      align-self: start;
    }
    p {
      align-self: start;
    }
    a {
      width: 50%;
    }
    a:first-of-type {
      margin-top: 20px;
    }
    div {
      padding: 10px 0;
      p {
        font-size: 12px;
      }
    }
  }

  .summary {
    border: 1px solid #E4E4E4;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    background-color: white;
    grid-column: 2;

    h1 {
      font-size: 36px;
    }

    div {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      padding: 10px;
    }
    .summary-total {
      background-color: #FFF7EB;
      padding: 10px;
      margin-top: 10px;
      font-weight: 600;
    }

    button {
      align-self: center;
      margin: 10px 0;
    }

    .button-add-coupon {
      transition: .2s;
      &:hover {
        color: #786785;
      }
    }

    .payment-method-icons {
      display: flex;
      justify-content: center;
      gap: 20px;
      font-size: 26px;
    }
  }
}

@media only screen and (max-width: 990px) {
  .cart-wrapper {
    display: grid;
    grid-template-columns: 100%;
    margin-top: 30px;
    gap: 0;
  }
}