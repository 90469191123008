.max-w-l {
  max-width: 1420px;
}

.border-radius {
  border-radius: 16px;
}

.modal-wrapper {
  width: 100%;
}
.modal-wrapper .modal-title {
  border-bottom: solid 1px hsl(0, 0%, 80%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  font-size: 20px;
  padding: 10px;
}
.modal-wrapper .modal-title svg {
  cursor: pointer;
  transition: 0.2s;
}
.modal-wrapper .modal-title svg:hover {
  color: #aaa;
}
.modal-wrapper .modal-content {
  width: 100%;
  margin-top: 50px;
}
.modal-wrapper .modal-content form > div {
  width: 100%;
}
.modal-wrapper .modal-content form > div input:not(input[type=select]) {
  border: 1px hsl(0, 0%, 80%) solid;
  border-radius: 4px;
  min-height: 38px;
  padding-left: 10px;
  padding-right: 10px;
  transition: 0.2s;
  width: 100%;
}
.modal-wrapper .modal-content form > div input:not(input[type=select]):focus {
  border-color: #a44fe7;
}

.cart-badge {
  position: absolute;
  background-color: #e75b48;
  display: block;
  min-width: 25px;
  padding: 1px 10px;
  text-align: center;
  border-radius: 80px;
  color: #ffffff;
  top: 30px;
  right: -5px;
}

nav.desktop {
  display: flex;
  grid-template-columns: 100%;
  flex-direction: column;
  position: fixed;
  background-color: white;
  left: 0;
  right: 0;
  z-index: 99;
  -webkit-box-shadow: 0 0 17px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 0 17px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 0 0 17px -4px rgba(0, 0, 0, 0.75);
}
nav.desktop .nav-wrapper {
  width: 100%;
  align-self: center;
  display: grid;
  grid-template-columns: 30% 1fr 30%;
  align-items: center;
  height: 71px;
  align-content: center;
}
nav.desktop .nav-wrapper .identity {
  display: flex;
  font-weight: 600;
  font-size: 20px;
}
nav.desktop .nav-wrapper .identity a {
  justify-self: center;
  display: flex;
  gap: 10px;
}
nav.desktop .nav-wrapper .identity img {
  width: 65px;
  height: 65px;
}
nav.desktop .nav-wrapper .identity .shop-name {
  align-self: center;
}
nav.desktop .nav-wrapper .navigation-links {
  justify-self: end;
  height: 100%;
}
nav.desktop .nav-wrapper .navigation-links ul {
  gap: 20px;
  display: flex;
  height: 100%;
}
nav.desktop .nav-wrapper .navigation-links ul .user-icon:hover .user-dropdown {
  display: block;
}
nav.desktop .nav-wrapper .navigation-links ul .user-dropdown:hover .user-icon {
  transition: 0.2s;
}
nav.desktop .nav-wrapper .navigation-links ul .user-dropdown:hover .user-icon:hover {
  color: #aaa;
}
nav.desktop .nav-wrapper .navigation-links ul li.user-icon {
  height: 71px;
}
nav.desktop .nav-wrapper .navigation-links ul li.user-icon svg {
  width: 25px;
}
nav.desktop .nav-wrapper .navigation-links ul li {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 50px;
  justify-content: center;
}
nav.desktop .nav-wrapper .navigation-links ul li svg {
  width: 30px;
  height: auto;
}
nav.desktop .nav-wrapper .navigation-links ul li a {
  display: flex;
}
nav.desktop .nav-wrapper .navigation-links ul li .user-dropdown:hover {
  display: block;
}
nav.desktop .nav-wrapper .navigation-links ul li .user-dropdown {
  display: none;
  position: absolute;
  width: 200px;
  background-color: white;
  top: 71px;
  right: -10px;
  border: 1px solid black;
  border-top: 0;
}
nav.desktop .nav-wrapper .navigation-links ul li .user-dropdown ul {
  display: flex;
  flex-direction: column;
  gap: 0;
}
nav.desktop .nav-wrapper .navigation-links ul li .user-dropdown ul p {
  padding: 10px;
  border-bottom: 1px solid black;
}
nav.desktop .nav-wrapper .navigation-links ul li .user-dropdown ul li {
  border-top: 1px solid black;
}
nav.desktop .nav-wrapper .navigation-links ul li .user-dropdown ul li a, nav.desktop .nav-wrapper .navigation-links ul li .user-dropdown ul li button {
  display: inline-block;
  width: 100%;
  padding: 10px;
  text-align: left;
  transition: 0.2s;
}
nav.desktop .nav-wrapper .navigation-links ul li .user-dropdown ul li a:hover, nav.desktop .nav-wrapper .navigation-links ul li .user-dropdown ul li button:hover {
  color: #aaa;
}
nav.desktop .nav-wrapper .navigation-links ul li .user-dropdown ul li:first-of-type {
  border-top: 0;
}
nav.desktop .nav-wrapper .navigation-links ul a {
  transition: 0.2s;
}
nav.desktop .nav-wrapper .navigation-links ul a:hover {
  color: #aaa;
}

nav.mobile {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 99;
  background-color: white;
  justify-content: center;
  -webkit-box-shadow: 0 0 17px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 0 17px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 0 0 17px -4px rgba(0, 0, 0, 0.75);
}
nav.mobile .nav-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 80% 20%;
  height: 71px;
  align-items: center;
  align-self: center;
  align-content: center;
}
nav.mobile .nav-wrapper .identity {
  display: flex;
  font-weight: 600;
  font-size: 20px;
}
nav.mobile .nav-wrapper .identity img {
  width: 32px;
  height: 32px;
}
nav.mobile .nav-wrapper .identity a {
  justify-self: center;
  display: flex;
  gap: 20px;
  margin-left: 20px;
}
nav.mobile .nav-wrapper a {
  transition: 0.2s;
  position: relative;
  justify-self: end;
}
nav.mobile .nav-wrapper a span.cart-badge {
  right: -15px;
  top: 35px;
}
nav.mobile .nav-wrapper a:hover {
  color: #aaa;
}
nav.mobile .nav-wrapper a svg {
  width: 30px;
  height: 80px;
}
nav.mobile .mobile-menu {
  background-color: white;
  display: flex;
  position: fixed;
  top: 71px;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 0.2s;
  justify-content: center;
}
nav.mobile .mobile-menu ul {
  margin-top: 20px;
  width: 90%;
  display: flex;
  flex-direction: column;
  font-size: 26px;
  gap: 20px;
}
nav.mobile .mobile-menu ul div {
  position: fixed;
  bottom: 20px;
}
nav.mobile .mobile-menu ul div li:not(:last-of-type) {
  margin-bottom: 20px;
}
nav.mobile .mobile-menu ul div li:not(:last-of-type) svg {
  margin-right: 30px;
}
nav.mobile .mobile-menu ul div svg {
  margin-right: 15px;
}
nav.mobile .menu-open {
  transform: translateX(0%) !important;
}
nav.mobile .menu-closed {
  transform: translateX(-100%) !important;
}

.main-wrapper {
  top: 71px;
  position: relative;
}

.hero-image {
  width: 100%;
  height: 587px;
  position: relative;
  z-index: 1;
  background-image: linear-gradient(rgba(48, 48, 48, 0.57), rgba(48, 48, 48, 0.57)), url("/public/image 1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.hero-image .hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -45%);
  color: white;
}
.hero-image .hero-text h1 {
  font-size: 48px;
  font-weight: 600;
}
.hero-image .hero-text p {
  max-width: 700px;
  margin-top: 70px;
  font-size: 20px;
}

.categories-navigation-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.categories-navigation-wrapper .categories-navigation {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  gap: 20px;
  text-align: center;
}
.categories-navigation-wrapper .categories-navigation .category-div {
  max-width: 400px;
}
.categories-navigation-wrapper .categories-navigation .category-div .category-image {
  width: 100%;
  height: auto;
  transition: 0.2s;
}
.categories-navigation-wrapper .categories-navigation .category-div .category-image:hover {
  -webkit-box-shadow: 0 0 17px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 0 17px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 0 0 17px -4px rgba(0, 0, 0, 0.75);
}
.categories-navigation-wrapper .categories-navigation .category-div p {
  margin-top: 10px;
  font-size: 24px;
}

.breadcrumb-separator {
  color: #aaa;
}

.breadcrumb {
  color: #aaa;
}

.homepage-wrapper {
  width: 100%;
}

.separator-text {
  text-align: center;
  width: 100%;
  padding: 40px 0;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: 30px;
}
.separator-text span {
  height: 1px;
  background-color: #aaa;
  width: auto;
}
.separator-text p {
  font-size: 32px;
  width: fit-content;
}

.loader-icon {
  font-size: 70px;
}

.products-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.products-wrapper .products-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  gap: 20px;
  text-align: center;
}
.products-wrapper .products-grid a {
  display: flex;
  justify-content: center;
  height: 100%;
}
.products-wrapper .products-grid .button-see-more {
  margin-top: 20px;
  background-color: #250041;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 90px;
  grid-column: 2;
}
.products-wrapper .products-grid .button-see-more:hover {
  background-color: #5a426e;
}
.products-wrapper .product-div {
  background-color: white;
  display: grid;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 40px;
  transition: 0.2s;
  border: 2px solid #B95BFE;
  width: 80%;
}
.products-wrapper .product-div:hover {
  -webkit-box-shadow: 0 0 17px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 0 17px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 0 0 17px -4px rgba(0, 0, 0, 0.75);
}
.products-wrapper .product-div .product-title {
  margin-top: 20px;
  font-size: 32px;
}
.products-wrapper .product-div .product-subcat {
  font-size: 16px;
  font-weight: 300;
  margin-top: -8px;
}
.products-wrapper .product-div .product-price {
  margin-top: 10px;
  color: #B41E0A;
  font-size: 20px;
  align-self: end;
}
.products-wrapper .product-div button {
  margin-top: 20px;
  width: 100%;
  align-self: end;
}
.products-wrapper .product-div .product-image {
  object-fit: cover;
}

.button-action {
  text-align: center;
  color: #ffffff;
  background-color: #B95BFE;
  padding: 10px 0;
  transition: 0.2s;
  width: 75%;
}
.button-action:hover {
  background-color: #c990ee;
  -webkit-box-shadow: 0 0 17px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 0 17px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 0 0 17 -4px rgba(0, 0, 0, 0.75);
}
.button-action:disabled {
  background-color: #786785;
  opacity: 0.6;
}

.button-delete {
  background-color: #fe5b5b;
}
.button-delete:hover {
  background-color: #ee9090;
}
.button-delete:disabled {
  background-color: #856767;
}

footer {
  -webkit-box-shadow: 0 0 17px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 0 17px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 0 0 17px -4px rgba(0, 0, 0, 0.75);
  background-color: #fff;
  height: 541px;
  width: 100%;
  margin-top: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  flex-direction: column;
  position: relative;
}
footer img {
  width: 133px;
  height: 133px;
  margin-top: -60px;
}
footer > a, footer > p {
  margin-top: 20px;
}
footer .copyright {
  position: absolute;
  bottom: 0;
  padding: 30px;
  left: 0;
  right: 0;
  text-align: center;
  border-top: 1px solid #D1D1D1;
}

.login-wrapper {
  justify-content: center;
  min-height: 65vh;
}
.login-wrapper .login-modal {
  -webkit-box-shadow: 0 0 17px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 0 17px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 0 0 17px -4px rgba(0, 0, 0, 0.75);
  margin-top: -200px;
  background-color: #fff;
  width: 600px;
  border: 2px solid #B95BFE;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 40px 0;
  position: absolute;
  top: 300px;
}
.login-wrapper .login-modal .input-login-control {
  width: 75%;
}
.login-wrapper .login-modal .input-login-control input {
  width: 100%;
}
.login-wrapper .login-modal h1 {
  font-size: 26px;
  margin-bottom: 40px;
  font-weight: 600;
}
.login-wrapper .login-modal a {
  width: 75%;
}
.login-wrapper .login-modal .separator-text {
  width: 75%;
  padding: 0;
}
.login-wrapper .login-modal .separator-text p {
  font-size: 12px;
}
.login-wrapper .login-modal .button-register {
  margin-top: 0;
}
.login-wrapper .login-modal a {
  color: #a44fe7;
  font-size: 16px;
}
.login-wrapper .login-modal a:hover {
  color: #B95BFE;
}

.form-control {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

input.border-red {
  border-color: #e75b48 !important;
}

input.email-input {
  border: 1px solid black;
  height: 36px;
  background: url("/public/envelope.svg") no-repeat scroll 10px 7px;
  background-size: 20px 20px;
  padding-left: 40px;
  padding-right: 10px;
  transition: 0.2s;
}
input.email-input:focus {
  border-color: #a44fe7;
}

input.password-input {
  border: 1px solid black;
  height: 36px;
  background: url("/public/lock.svg") no-repeat scroll 10px 7px;
  background-size: 20px 20px;
  padding-left: 40px;
  padding-right: 10px;
  transition: 0.2s;
}
input.password-input:focus {
  border-color: #a44fe7;
}

input.username-input {
  border: 1px solid black;
  height: 36px;
  background: url("/public/at-light.svg") no-repeat scroll 10px 7px;
  background-size: 20px 20px;
  padding-left: 40px;
  padding-right: 10px;
  transition: 0.2s;
}
input.username-input:focus {
  border-color: #a44fe7;
}

input.firstname-input {
  border: 1px solid black;
  height: 36px;
  background: url("/public/id-card-light.svg") no-repeat scroll 10px 7px;
  background-size: 20px 20px;
  padding-left: 40px;
  padding-right: 10px;
  transition: 0.2s;
}
input.firstname-input:focus {
  border-color: #a44fe7;
}

input.lastname-input {
  border: 1px solid black;
  height: 36px;
  background: url("/public/id-card-light.svg") no-repeat scroll 10px 7px;
  background-size: 20px 20px;
  padding-left: 40px;
  padding-right: 10px;
  transition: 0.2s;
}
input.lastname-input:focus {
  border-color: #a44fe7;
}

.search-bar {
  justify-self: center;
  display: flex;
  width: 100%;
  position: relative;
}
.search-bar .search-bar-dropdown {
  position: absolute;
  flex-direction: column;
  background-color: white;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  max-height: 240px;
  overflow-y: scroll;
  top: 20px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  left: 0;
  right: 0;
  border: 1px solid #a44fe7;
  border-top: 0;
  z-index: 2;
  transition: 0.2s;
}
.search-bar .search-bar-dropdown ul {
  padding: 25px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.search-bar .search-bar-dropdown ul li a, .search-bar .search-bar-dropdown ul .no-result {
  padding: 10px 15px;
  border-radius: 16px;
  background-color: #eaeaea;
  display: grid;
  width: 100%;
  grid-template-columns: 60% auto 50px;
  align-items: center;
}
.search-bar .search-bar-dropdown ul li a span:last-of-type, .search-bar .search-bar-dropdown ul .no-result span:last-of-type {
  font-style: italic;
  font-size: 11px;
  justify-self: start;
  margin-bottom: -2px;
}
.search-bar .search-bar-dropdown ul li a svg, .search-bar .search-bar-dropdown ul .no-result svg {
  justify-self: end;
}
.search-bar input {
  border: 1px solid black;
  height: 36px;
  width: 100%;
  background: url("/public/search-alt.svg") no-repeat scroll 10px 7px;
  background-size: 20px 20px;
  padding-left: 40px;
  padding-right: 50px;
  transition: 0.2s;
  position: relative;
  z-index: 5;
  background-color: #fff;
}
.search-bar input:focus + button {
  border-color: #a44fe7;
}
.search-bar input:focus {
  border-color: #a44fe7;
}
.search-bar button {
  margin-left: -45px;
  border-left: 1px solid black;
  padding-left: 10px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  padding-right: 12px;
  transition: 0.2s;
  height: 34px;
  margin-top: 1px;
  position: relative;
  z-index: 5;
}
.search-bar button:hover {
  background-color: #c990ee;
}

@media only screen and (max-width: 1440px) {
  .max-w-l {
    max-width: 1220px;
  }
}
@media only screen and (max-width: 1240px) {
  .previous-page, .next-page {
    top: -60px !important;
  }
  .previous-page {
    left: 200px !important;
  }
  .next-page {
    right: 200px !important;
  }
  .category-wrapper .category-categories-wrapper, .category-wrapper .category-products-wrapper {
    grid-template-columns: 1fr 1fr 1fr !important;
  }
  .max-w-l {
    max-width: 1020px;
  }
  .categories-navigation {
    grid-template-columns: 1fr 1fr !important;
  }
  .products-grid {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
  }
}
@media only screen and (max-width: 1040px) {
  .order-status {
    width: 100% !important;
  }
  .max-w-l {
    max-width: 920px;
  }
}
@media only screen and (max-width: 990px) {
  .max-w-l {
    max-width: 90%;
  }
  .category-wrapper .category-categories-wrapper, .category-wrapper .category-products-wrapper {
    grid-template-columns: 50% 50% !important;
  }
  nav.desktop {
    display: none;
  }
  nav.mobile {
    display: flex;
  }
  .product-wrapper {
    margin-top: 30px !important;
    grid-template-columns: 100% !important;
    grid-template-rows: auto auto;
    gap: 30px !important;
  }
}
@media only screen and (max-width: 768px) {
  .order-product {
    width: 100% !important;
  }
  .previous-page {
    left: 100px !important;
  }
  .next-page {
    right: 100px !important;
  }
  .categories-navigation {
    grid-template-columns: 1fr !important;
  }
  .products-grid {
    display: grid;
    grid-template-columns: 1fr !important;
  }
  .products-grid .button-see-more {
    grid-column: 1 !important;
  }
  .products-wrapper .product-div {
    width: 75%;
  }
  .categories-navigation-wrapper {
    width: 100%;
  }
  .categories-navigation-wrapper .categories-navigation {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
    gap: 20px;
    text-align: center;
  }
  .categories-navigation-wrapper .categories-navigation .category-div {
    max-width: 75%;
  }
  .categories-navigation-wrapper .categories-navigation .category-div .category-image {
    width: 100%;
    height: auto;
  }
  .categories-navigation-wrapper .categories-navigation .category-div p {
    margin-top: 10px;
    font-size: 24px;
  }
}
@media only screen and (max-width: 755px) {
  .category-wrapper .category-products-wrapper {
    grid-template-columns: 1fr !important;
  }
  .category-wrapper .category-products-wrapper img {
    height: 400px !important;
  }
  .hero-image .hero-text {
    width: 80%;
  }
  .hero-image .hero-text h1 {
    max-width: 100%;
  }
  .hero-image .hero-text p {
    max-width: 100%;
    margin-top: 70px;
    font-size: 20px;
  }
}
@media only screen and (max-height: 850px) {
  .login-wrapper {
    min-height: 70vh;
  }
}
@media only screen and (max-height: 760px) {
  .login-wrapper {
    min-height: 80vh;
  }
}

