.orders-wrapper {
  padding-top: 70px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.orders-wrapper .order-status {
  display: flex;
  flex-direction: column;
  width: 75%;
  padding: 20px;
  background-color: #bceebd;
  border: 1px solid #57ab5a;
  gap: 10px;
  align-items: center;
}
.orders-wrapper .order-status h1 {
  font-size: 32px;
}
.orders-wrapper .order-product {
  margin-top: 40px;
  width: 500px;
  gap: 0 30px;
  display: grid;
  align-items: center;
  justify-items: start;
  grid-template-columns: 25% auto;
}
.orders-wrapper .order-product div {
  display: grid;
  grid-template-columns: 100px auto;
}
.orders-wrapper .order-product div:first-of-type {
  align-self: end;
}
.orders-wrapper .order-product div:last-of-type {
  align-self: start;
}
.orders-wrapper .order-product img {
  grid-row: 1/span 3;
}

