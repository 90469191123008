.main-wrapper-admin {
  margin-left: 200px;
}

.admin-dashboard-wrapper {
  margin-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: center;
}
.admin-dashboard-wrapper .products-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}
.admin-dashboard-wrapper .products-wrapper .products-table {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.admin-dashboard-wrapper .products-wrapper .products-table table {
  width: 75%;
}
.admin-dashboard-wrapper .categories-wrapper {
  margin-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: center;
}
.admin-dashboard-wrapper .categories-wrapper .categories-table {
  width: 75%;
}
.admin-dashboard-wrapper td, .admin-dashboard-wrapper th {
  padding: 10px;
}
.admin-dashboard-wrapper td button, .admin-dashboard-wrapper th button {
  width: 100%;
}

