label {
  color: #727272;
  font-size: 12px;
}

.account .address-form {
  display: grid;
  width: 300px;
  gap: 5px;
  grid-template-columns: 200px 150px 50px;
}
.account .address-form .city-input, .account .address-form .street-input {
  width: 100%;
}
.account .address-form .street-input {
  grid-row: 2;
  grid-column: 1/4;
}
.account .address-form .city-input {
  grid-row: 3;
  grid-column: 1/4;
}

.cart .address-form {
  display: grid;
  width: 100%;
  gap: 5px;
  grid-template-columns: auto 150px 100px;
}
.cart .address-form .city-input, .cart .address-form .street-input {
  width: 100%;
}
.cart .address-form .street-input {
  grid-row: 2;
  grid-column: 1/4;
}
.cart .address-form .city-input {
  grid-row: 3;
  grid-column: 1/4;
}
.cart .address-form .form-button-wrapper {
  width: 100%;
  grid-column: 1/span 3;
  display: flex;
  justify-content: center;
}

