.account-wrapper {
  margin-top: 30px;
  display: grid;
  width: 100%;
  grid-template-columns: 200px auto;
}
.account-wrapper h1 {
  font-size: 26px;
}
.account-wrapper h2 {
  font-size: 20px;
}
.account-wrapper input {
  border: 1px hsl(0, 0%, 80%) solid;
  border-radius: 4px;
  min-height: 38px;
  padding-left: 10px;
  padding-right: 10px;
  transition: 0.2s;
  width: 100% !important;
  margin-bottom: 10px;
}
.account-wrapper button {
  width: 200px;
}
.account-wrapper ul li {
  font-weight: 500;
  font-size: 18px;
}
.account-wrapper .personal-information {
  display: flex;
  flex-direction: column;
}
.account-wrapper .personal-information .row {
  display: grid;
  grid-template-columns: 150px 1fr;
}

