aside {
  margin: 0;
  padding: 0px 0;
  width: 200px;
  background-color: #ffffff;
  position: fixed;
  height: 100%;
  overflow: auto;
  left: 0;-webkit-box-shadow: 0 0 17px -4px rgba(0,0,0,0.75);
  -moz-box-shadow: 0 0 17px -4px rgba(0,0,0,0.75);
  box-shadow: 0 0 17px -4px rgba(0,0,0,0.75);

  a {
    display: block;
    color: black;
    padding: 16px;
    text-decoration: none;
    transition: .2s;
    display: flex;
    width: 100%;
    align-content: center;
    svg {
      margin-right: 10px;
      font-size: 20px;
    }
    &:hover {
      background-color: #a44fe7;
      color: white;
    }
  }
}